import { useEffect } from 'react'
import Router from 'next/router'
import useSWR from 'swr'
import _ from "lodash";
import moment from 'moment-timezone';

export default function useUser({
  redirectTo = false,
  redirectIfFound = false,
} = {}) {
  const fetcher = (url) => fetch(url).then((res) => res.json());
  const { data: user, mutate: mutateUser } = useSWR('/api/me/', fetcher)

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (!redirectTo || !user) return
    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && user?.isLoggedIn)
    ) {
      Router.push(redirectTo)
    }
  }, [user, redirectIfFound, redirectTo])

  useEffect(() => {
    if (window.$zmantovMobile) {
      if (user && user.isLoggedIn && user._id) {
        const nextUpdate = localStorage.getItem('ztMobileUpdated');

        if (!nextUpdate || (nextUpdate && (moment().isAfter(moment.unix(nextUpdate))))) {
          localStorage.setItem('ztMobileUpdated', moment().add(5, 'm').unix().toString());
          console.log('Mobile app update. Next update:', moment().add(5, 'm').format('YYYY-MM-DDTHH:mm:ss.000Z'))
          console.log('postMessage ReactNative:', user);
          window.ReactNativeWebView.postMessage(JSON.stringify(user));
        }
      }
    }

    if (user && user.isLoggedIn && user._id) {
      try {
        // console.log('Logged in user:', user._id, user.email, user.phone, user.business.pageCode)
        window.$crisp = window.$crisp || [];
        $crisp.push(["set", "user:email", [user.email]]);
        $crisp.push(["set", "user:phone", [`${user.phone}`]]);
        $crisp.push(["set", "user:nickname", [`${user.firstName} ${user.lastName}`]]);
        $crisp.push(["set", "user:company", [_.get(user, 'business.details.name', ''), { url: `https://zmantov.co.il/b/${user.business.pageCode}` }]]);
        $crisp.push(["set", "session:data", [[["pageCode", user.business.pageCode], ["businessId", user.business._id]]]]);

        if (window.clarity) {
          window.clarity("identify", user.business._id)
          window.clarity("set", "user", [user._id, user.email, user.phone, user.business.pageCode]);
        }
        // console.log('set user crisp/clarity details', user)
      } catch (e) {
        console.error(e)
      }
    }
  }, [user]);

  return { user, mutateUser }
}
