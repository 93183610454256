import moment from 'moment-timezone';
import _ from 'lodash';

const updateObject = (obj, newValues) => {
  Object.entries(newValues).forEach(([key, value]) => {
    obj[key] = value;
  });
  return obj;
}

function changeKeyName(obj, oldName, newName) {
  obj[newName] = obj[oldName]
  delete obj[oldName]
}

function validateEmail(email) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailPattern.test(email)
}

module.exports = {
  changeKeyName,
  validateEmail,
  randomId: function (length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  },
  splitName: function (name = '') {
    const [firstName, ...lastName] = name.split(' ').filter(Boolean);
    return {
      firstName: firstName,
      lastName: lastName.join(' ')
    }
  },
  timeConvert: function (n) {
    let hours = (n / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

    if (rhours === 0 && rminutes > 0) {
      return rminutes + " דק׳";
    }
    if (rhours > 0 && rminutes === 0) {
      if (rhours === 1) {
        return 'שעה'
      } else if (rhours === 2) {
        return 'שעתיים'
      }
      return rhours + " שעות";
    }

    if (rhours === 1) {
      return " שעה " + "ו" + rminutes + " דק׳";
    } else if (rhours === 2) {
      return " שעתיים " + "ו" + rminutes + " דק׳";
    } else {
      return rhours + " שעות " + "ו" + rminutes + " דק׳";
    }
  },
  getDayName: function (day, short) {
    switch (day) {
      case 1:
        return short ? 'א' : 'ראשון'
      case 2:
        return short ? 'ב' : 'שני'
      case 3:
        return short ? 'ג' : 'שלישי'
      case 4:
        return short ? 'ד' : 'רביעי'
      case 5:
        return short ? 'ה' : 'חמישי'
      case 6:
        return short ? 'ו' : 'שישי'
      case 7:
        return short ? 'ש' : 'שבת'
    }
  },
  updateObject,
  getTimePeriods: (fromTime, toTime, interval) => {
    const endTime = moment(toTime, 'HH:mm');
    const startTimeMoment = moment(fromTime, 'HH:mm');

    const periodsInADay = moment.duration(endTime.diff(startTimeMoment)).as('minutes');
    const timeLabels = [];

    for (let i = 0; i <= periodsInADay; i += interval) {
      startTimeMoment.add(i === 0 ? 0 : interval, 'minutes');
      let theTimeOption = { time: startTimeMoment.format('HH:mm'), available: true };
      timeLabels.push(theTimeOption);
    }
    return timeLabels;
  },
  objectToDateString: function (selectedDay, time) {
    return `${selectedDay.year}-${selectedDay.month < 10 ? '0' + selectedDay.month : selectedDay.month}-${selectedDay.day < 10 ? '0' + selectedDay.day : selectedDay.day}T${time}`;
  },
  toReadablePhone: function (phone) {
    return phone.substr(0, 3) + "-" + phone.substr(3, phone.length);
  },
  getInitials: function (firstName, lastName) {
    let initials = firstName.substring(0, 1).toUpperCase();
    if (lastName) {
      initials += lastName.substring(0, 1).toUpperCase();
    }
    return initials;
  },
  todayNormalized12am: function () {
    const now = Date.now();
    return now - (now % (60 * 60 * 24 * 1000));
  },
  cleanPhone: function (phone) {
    if (!phone) return null;

    let p = phone;
    if (p.startsWith('97205')) {
      p = p.replace('97205', '05');
    } else if (p.startsWith('972')) {
      p = p.replace('972', '0');
    } else if (p.startsWith('+9720')) {
      p = p.replace('+9720', '0');
    } else if (p.startsWith('+972')) {
      p = p.replace('+972', '0');
    }
    p = p.replace(/ /g, '');
    p = p.replace(/\D/g, '');
    p = p.replace(/-/g, '');
    return p
  },
}

function getTimesInRange(desiredStartTime, desiredEndTime, interval, period) {
  const periodsInADay = moment.duration(1, 'day').as(period);
  const timeLabels = [];
  const startTimeMoment = moment(desiredStartTime, 'hh:mm');
  const endTimeMoment = moment(desiredEndTime, 'hh:mm');
  for (let i = 0; i <= periodsInADay; i += interval) {
    if (startTimeMoment.isBefore(endTimeMoment)) {
      startTimeMoment.add(i === 0 ? 0 : interval, period);
      timeLabels.push(startTimeMoment.format('hh:mm'));
    }
  }
  return timeLabels;
}