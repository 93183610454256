const _ = require('lodash');

function isBeforeToday(date) {
	return date < (new Date()).normalizeTo12Am()
}

function getDateCombinedWithHour(date, hour) {
	return date.clone().set({ hour: hour.hour(), minute: hour.minute() })
}

function getMonths() {
	const months = []
	for (let i = 1; i < 13; i++) {
		months.push(i)
	}
	return months
}

function getFollowingYears() {
	const currentYear = new Date().getFullYear()
	const years = [currentYear]
	for (let i = 1; i < 11; i++) {
		years.push(currentYear + i)
	}
	return years
}

function isOnSameTime(newEventStartTime, newEventEndTime, existingEventStartTime, existingEventEndTime) {
	let isOnSameTime = false
	if (newEventStartTime.isBefore(existingEventEndTime) && newEventEndTime.isAfter(existingEventStartTime)) isOnSameTime = true
	return isOnSameTime
}

function checkIsToday(formattedDay) {
	const today = new Date()
	return (today.getFullYear() === formattedDay.year && today.getMonth() === formattedDay.month && today.getDate() === formattedDay.day)
}

const dateUtils = module.exports = {
	/**
	 * @returns {Number} today's date with hours, minutes, seconds, milliseconds set to 0, i.e. absolute start of date.
	 */
	getDateCombinedWithHour,
	getMonths,
	getFollowingYears,
	isOnSameTime,
	convertMomentDateToUTCDate,
	checkIsToday,
	todayNormalized12am() {
		let now = Date.now();
		return now - (now % Date.MILLISECONDS_IN_DAY);
	},

	/** @param {Date} date */
	normalizeTo12Am(date) {
		return this.normalize(date);
	},

	/**
	 * @returns {number} current minute of the day range {0-1439}
	 */
	minuteOfTheDay() {
		let now = Date.now();
		return Math.floor((now % Date.MILLISECONDS_IN_DAY) / Date.MILLISECONDS_IN_MINUTE);
	},
	/**
	 * @param days number of days to add or subtract
	 * @returns {Date} today plus/minus days
	 */
	todayWithAddedDays(days) {
		const now = new Date();
		return now.addDays(days);
	},

	/**
	 * @param {Date} date
	 * @param {number} months
	 */
	dateByAddingMonths(date, months) {
		if (!this.isValid(date)) return date;
		const retval = new Date(date);
		retval.setUTCMonth(retval.getUTCMonth() + months);
		return retval;
	},

	dateByAddingDays: function (date, days) {
		return new Date((new Date(date)).getTime() + days * dateUtils.MILLISECONDS_IN_DAY);
	},

	firstDayOfMonth() {
		const now = new Date();
		const timestamp = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1)
		return new Date(timestamp);
	},

	dayOfMonth() {
		const now = new Date();
		return now.getUTCDate();
	},

	isToday(timestamp) {
		return this.isSameDay(Date.now(), timestamp);
	},

	isSameDay(timestamp1, timestamp2) {
		return timestamp1 === timestamp2 || Math.abs(timestamp1 - timestamp2) < Date.MILLISECONDS_IN_DAY;
	},

	daysBetween(d1, d2, { floor = false } = {}) {
		let time1 = d1 && d1.getTime && d1.getTime();
		let time2 = d2 && d2.getTime && d2.getTime();
		if (isNaN(time1) && typeof d1 === 'number') time1 = d1;
		if (isNaN(time2) && typeof d2 === 'number') time2 = d2;
		if (!time1 || isNaN(time1) || !time2 || isNaN(time2)) return NaN;

		if (floor) {
			return Math.floor(Math.abs(time1 - time2) / Date.MILLISECONDS_IN_DAY);
		}
		return Math.ceil(Math.abs(time1 - time2) / Date.MILLISECONDS_IN_DAY);
	},

	normalize(date) {
		if (this.isValid(date)) {
			date.setUTCHours(0, 0, 0, 0);
			return date;

		} else if (!isNaN(date) && _.isNumber(date)) {
			return date - date % Date.MILLISECONDS_IN_DAY;
		}
	},

	/**
	 * @param {Date} date
	 */
	getSubscriptionUntilDate(date) {
		date = new Date(date);
		if (!this.isValid(date)) return null;

		const now = Date.now();
		const retval = new Date();
		retval.setUTCDate(date.getUTCDate());
		retval.setUTCHours(0, 0, 0, 0);
		if (retval < now) {
			retval.setUTCMonth(retval.getUTCMonth() + 1);
		}
		return retval;
	},

	/**
	 * @param {Date} date
	 * @return {Date}
	 */
	getCycleStartDate(date) {
		date = new Date(date);
		if (!this.isValid(date)) return null;

		const now = Date.now();
		const retval = new Date();
		retval.setUTCDate(date.getUTCDate());
		retval.setUTCHours(0, 0, 0, 0);
		if (retval > now) {
			retval.setUTCMonth(retval.getUTCMonth() - 1);
		}
		return retval;
	},

	isValid(date) {
		if (!date) return false;
		return !!(date.getTime && !isNaN(date.getTime()));
	},

	/**
	 * check if a timestamp is a seconds format or milliseconds
	 * based on: seconds timestamp counted as milliseconds is always sometime in 1970.
	 * @param timestamp the timestamp to check
	 * @return {Boolean} if timestamp is seconds
	 */
	isSecondsTimestamp(timestamp) {
		const ts = parseInt(timestamp, 10);
		if (isNaN(ts)) return false;

		const threshold = 946684800000; //01-01-2010 00:00:00 UTC
		return ts < threshold;
	},

	getISODate(date) {
		if (!date) return;
		return date.toISOString().split('T')[0];
	},
	isBeforeToday
};

dateUtils.MINUTES_IN_DAY = 1440;
dateUtils.MINUTES_IN_HOUR = 60;
dateUtils.SECONDS_IN_DAY = 60 * 60 * 24;
dateUtils.MILLISECONDS_IN_MINUTE = 60 * 1000;
dateUtils.MILLISECONDS_IN_DAY = dateUtils.SECONDS_IN_DAY * 1000;
dateUtils.MILLISECONDS_IN_HOUR = dateUtils.MILLISECONDS_IN_MINUTE * 60;

Date.MINUTES_IN_DAY = 1440;
Date.MINUTES_IN_HOUR = 60;
Date.SECONDS_IN_DAY = 60 * 60 * 24;
Date.MILLISECONDS_IN_MINUTE = 60 * 1000;
Date.MILLISECONDS_IN_DAY = Date.SECONDS_IN_DAY * 1000;
Date.MILLISECONDS_IN_HOUR = Date.MILLISECONDS_IN_MINUTE * 60;

function convertMomentDateToUTCDate(momentDate) {
	const utcOffset = momentDate.utcOffset()
	return new Date(momentDate.valueOf() + utcOffset * dateUtils.MILLISECONDS_IN_MINUTE)
}

Date.dateByAddingDays = function (date, days) {
	return new Date((new Date(date)).getTime() + days * dateUtils.MILLISECONDS_IN_DAY);
};

Date.minuteOfTheDay = function () {
	return dateUtils.minuteOfTheDay();
};

/**
 * Returns number of days between two days (unsigned integer)
 * @param {Date | number} d1 first date
 * @param {Date | number} d2 second date
 * @returns {number} number of days or -1 if invalid
 */
Date.daysBetween = function (d1, d2) {
	let time1 = d1 && d1.getTime && d1.getTime();
	let time2 = d2 && d2.getTime && d2.getTime();
	if (isNaN(time1) && typeof d1 === 'number') time1 = d1;
	if (isNaN(time2) && typeof d2 === 'number') time2 = d2;
	if (!time1 || isNaN(time1) || !time2 || isNaN(time2)) return NaN;

	return Math.floor(Math.abs(time1 - time2) / Date.MILLISECONDS_IN_DAY);
};

/**
 * Add hours to the date
 * @param {number} hours to add or subtract (if negative) to date
 * @returns {Date} this date with changed hours (for chaining)
 */
Date.prototype.addHours = function (hours) {
	this.setHours(this.getHours() + hours);
	return this;
};

/**
 * Add minutes to date
 * @param {number} minutes to add or subtract (if negative) to date
 * @returns {Date} this date with changed minutes (for chaining)
 */
Date.prototype.addMinutes = function (minutes) {
	this.setMinutes(this.getMinutes() + minutes);
	return this;
};

/**
 * Add days to date
 * @param {number} days to add or subtract (if negative) to date
 * @returns {Date} this date with changed minutes (for chaining)
 */
Date.prototype.addDays = function (days) {
	this.setDate(this.getDate() + days);
	return this;
};

Date.prototype.normalizeTo12Am = function () {
	this.setUTCHours(0, 0, 0, 0);
	return this;
};

Date.prototype.equals = function (date) {
	let otherTime = date && date.getTime && date.getTime();
	if (!otherTime && typeof date === 'number') otherTime = date;
	return this.getTime() === otherTime;
};

/**
 * @returns boolean true if date is valid, false otherwise
 */
Date.prototype.isValid = function () {
	return !isNaN(this.getTime());
};

Date.prototype.getFirstDayOfWeek = function () {
	this.addDays(-this.getDay())
	return this
}

Date.prototype.getLastDayOfWeek = function () {
	this.addDays(6 - this.getDay())
	return this
}