module.exports = {
  notificationTypes: {
    NEW_APPOINTMENT: 'NEW_APPOINTMENT',
    NEW_MANUAL_APPOINTMENT: 'NEW_MANUAL_APPOINTMENT',
    NEW_CUSTOMER: 'NEW_CUSTOMER',
    CANCELLED: 'CANCELLED',
    CUSTOMER_REGISTERED_FOR_GROUP: 'CUSTOMER_REGISTERED_FOR_GROUP',
    CUSTOMER_CANCELLED_GROUP_REGISTRATION: 'CUSTOMER_CANCELLED_GROUP_REGISTRATION'
  },
  appointmentStatus: {
    NOT_CONFIRMED: 'NOT_CONFIRMED',
    CONFIRMED: 'CONFIRMED',
    CANCELLED: 'CANCELLED'
  },
  IPN_TYPES: {
    createWebCheckoutCardChangeSession: 'createWebCheckoutCardChangeSession',
    createWebCheckoutSession: 'createWebCheckoutSession',
    webCheckoutCardChangeError: 'webCheckoutCardChangeError',
    webCheckoutError: 'webCheckoutError',
    tokenTransactionError: 'tokenTransactionError',
    webCheckoutValidateCallback: 'webCheckoutValidateCallback',
    creditCardChangeCallback: 'creditCardChangeCallback',
    commitTransactionWithTokenRequest: 'commitTransactionWithTokenRequest',
    commitTransactionWithTokenResponse: 'commitTransactionWithTokenResponse'
  },
  RECENT_IPNS: {
    CANCELLATION: 'CANCELLATION',
    CONTRACT_CHANGE: 'CONTRACT_CHANGE',
    CHARGE: 'CHARGE',
    RECURRING: 'RECURRING',
    DECLINE: 'DECLINE'
  },
  AVAILABILITY_TYPES: {
    TIMEOFF: 'timeoff',
    VACATION: 'vacation',
    HOLIDAY: 'holiday',
    SPECIAL_OPERATING_HOURS: 'specialOperatingHours'
  },
  SMS_TYPES: {
    REMINDER: 'reminder',
    CLIENT_MESSAGE: 'clientMessage',
    BUSINESS_MESSAGE: 'businessMessage'
  },
  steps: {
    step1: 1,
    step2: 2,
    step3: 3,
    step4: 4,
    step5: 5
  },
  CALENDAR_TYPES: {
    GROUP: 'group',
    TIMEOFF: 'timeoff',
    VACATION: 'vacation'
  },
  ERRORS: {
    INVALID_PHONE: 'invalidPhone',
    ALREADY_EXISTS: 'alreadyExists',
    BUSINESS_NOT_FOUND: 'businessNotFound',
    PAYMENT_ALREADY_COMPLETE: 'paymentAlreadyComplete',
    INVALID_EMAIL: 'invalidEmail',
    EMPTY_NAME: 'emptyName'
  },
  PAYMENT_METHODS: {
    CASH: 'cash',
    MANUAL: 'manual',
    CREDIT: 'credit'
  },
  PAYMENT_STATUS: {
    PAYMENT_COMPLETE: 'paymentComplete',
    PENDING_CUSTOMER_APPROVAL: 'pendingCustomerApproval'
  },
  GENDER: {
    MALE: 'male',
    FEMALE: 'female',
    OTHER: 'other'
  },
  MESSAGE_PARAMS: {
    CUSTOMER_NAME: 'שם הלקוח/ה',
    BUSINESS_NAME: 'שם העסק',
    APPOINTMENT_TIME: 'זמן התור',
    URL: 'לינק לפרטי התור'
  },
  CUSTOM_MESSAGE_TYPES: {
    NEW_APPOINTMENT_MESSAGE: 'newAppointmentMessage',
    REMINDER_MESSAGE: 'reminderMessage',
    CANCELLATION_MESSAGE: 'cancellationMessage'
  },
  CUSTOMERS_LIMIT: 400,
  REMINDER_TIMES: [
    { value: 2, text: 'שעתיים' },
    { value: 3, text: '3 שעות' },
    { value: 4, text: '4 שעות' },
    { value: 5, text: '5 שעות' },
    { value: 6, text: '6 שעות' },
    { value: 7, text: '7 שעות' },
    { value: 8, text: '8 שעות' },
    { value: 9, text: '9 שעות' },
    { value: 10, text: '10 שעות' },
    { value: 11, text: '11 שעות' },
    { value: 12, text: '12 שעות' },
    { value: 24, text: '24 שעות' },
    { value: 36, text: '36 שעות' },
    { value: 48, text: '48 שעות' }
  ]
}