import React from 'react';
import { CgSpinner } from "react-icons/cg";

function Loading() {
	return (
		<div className='fixed inset-0 bg-white flex flex-col items-center justify-center space-y-4 text-2xl z-50'>
			<CgSpinner className='mx-auto animate-spin text-green-600' size={60} />
			<div>טוען...</div>
		</div>
	);
}

export default Loading;

